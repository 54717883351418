










































import {Vue, Component} from "vue-property-decorator";
import {KeyWords} from "@/request/webDomain/KeyWords";

@Component({
    name: 'webDomain_keywords'
})
export default class webDomain_keywords extends Vue {
    modal: any = KeyWords;
    columnTypes: any = [
        {
            label: '首页',
            value: 0
        },
        {
            label: '营销saas+新零售',
            value: 1
        },
        {
            label: '商家申请',
            value: 2
        },
        {
            label: '动态信息',
            value: 3
        },
        {
            label: '联系我们',
            value: 4
        }
    ];
    formData: any = {
        columnType: 0,
        keyword: '',
        desc: ''
    };
    formRules: any = {
        columnType: {required: true, type: 'number', trigger: 'blur'},
        keyword: {required: true, trigger: 'blur'},
    };
    columns: Array<any> = [
        {
            title: '模块名字',
            key: 'columnName',
            minWidth: 140
        },
        {
            title: '关键字',
            key: 'keyword',
            tooltip: true,
            minWidth: 160
        },
        {
            title: '描述',
            key: 'desc',
            tooltip: true,
            minWidth: 160
        }
    ];
    created() {

    }
    createNew() {
        delete this.formData.id;
    }
    edit(row: any) {
        this.formData = row;
    }
    submit() {
        let self: any = this;
        let params = {...self.formData};
        self.$refs['el-form'].validate((valid: boolean) => {
            if (valid) {
                self.$refs['el-table'].doSubmit(params).then(() => {

                });
            }
        })
    }
}
